<template>
  <div>
    <div
      class="import-background position-relative rounded-panel px-4 py-3 mt-4"
      @click="showModal"
    >
      <div
        class="d-flex justify-content-between align-items-center py-2 cursor-pointer"
      >
        <b
          class="text-value-lg fw-light d-block rounded-panel-title text-white"
        >
          Import Users
          <HelpCenterButton
            width="16"
            height="16"
            classes="me-2"
            content-key="import_users_user_menu"
            :is-old="true"
          />
        </b>
        <span
          class="d-flex align-items-center justify-content-center text-white rounded-circle p-2"
        >
          <i class="flaticon-upload text-value-lg"></i>
        </span>
      </div>
    </div>

    <!-- Import Modal -->
    <CModal
      :scrollable="true"
      backdrop="static"
      :visible="state.isModalVisible"
      :close-on-backdrop="false"
      class="ps__child--consume"
      size="xl"
    >
      <div
        class="pt-4 px-4 create-popup-header d-flex align-items-center justify-content-between"
      >
        <div
          class="d-inline-block bg-gradient-blue text-white rounded-circle p-1 lh-normal cursor-pointer"
          @click=";(state.isModalVisible = false), resetImportData()"
        >
          <span class="close-icon"></span>
        </div>
      </div>
      <Loader :is-processing="state.isLoading" />
      <CContainer class="px-5 py-4">
        <CRow>
          <CCol md="12">
            <h2>Steps for adding users by CSV upload</h2>
            <p style="font-family: &quot;Metropolis-Bold&quot;">
              This import option allows schools to provision users by loading a
              Comma-Separated Values (CSV) file. The file must contain a first
              name, last name, email, role, and status for all users. It is an
              option to also provide a Graduation Year and an ID number. The
              Graduation year should be in 4-digit format, 2022 not just 22. You
              can leave this column blank if you do not wish to provide a
              graduation year, but you should not have either "null" or "0" zero
              in this column. It should either be four-digit years or blank.
              Likewise, if you do not wish to provide an ID number, just leave
              that column blank.
            </p>
            <p>
              1. Click Browse and locate the prepared user CSV file.<br />
              2. Click Submit.<br />
            </p>
            <p style="font-family: &quot;Metropolis-Bold&quot;">
              Here is a sample CSV user file in the required format:
              <a href="/csv-samples/users-import-sample.csv" download
                >CSV File Format</a
              >. Do not modify headers or the order of columns.
            </p>
            <p style="font-family: &quot;Metropolis-Bold&quot;">
              Once you upload the file, if the file was successful, users will
              be imported and visible under Main Users. You can see the file
              progress on the "File Uploads" page.
            </p>
            <div>
              <div class="form-group">
                <input
                  :class="{
                    'is-invalid':
                      Object.keys(state.reqResponse.errors).length > 0
                  }"
                  readonly
                  type="text"
                  class="form-control w-auto pe-5"
                  :value="state.params.file ? state.params.file.name : ''"
                  @click="openFileIpnut('csvUsers')"
                />
                <div
                  v-if="state.reqResponse.errors['email']"
                  class="invalid-feedback"
                >
                  {{ state.reqResponse.errors["email"][0] }}
                </div>
                <div
                  v-if="state.reqResponse.errors['firstname']"
                  class="invalid-feedback"
                >
                  {{ state.reqResponse.errors["firstname"][0] }}
                </div>
                <div
                  v-if="state.reqResponse.errors['lastname']"
                  class="invalid-feedback"
                >
                  {{ state.reqResponse.errors["lastname"][0] }}
                </div>
                <div
                  v-if="state.reqResponse.errors['role']"
                  class="invalid-feedback"
                >
                  {{ state.reqResponse.errors["role"][0] }}
                </div>
                <div
                  v-if="state.reqResponse.errors['status']"
                  class="invalid-feedback"
                >
                  {{ state.reqResponse.errors["status"][0] }}
                </div>
                <div
                  v-if="state.reqResponse.errors['csv_file']"
                  class="invalid-feedback"
                >
                  {{ state.reqResponse.errors["csv_file"][0] }}
                </div>
              </div>
              <CAlert
                v-if="state.reqResponse.message"
                class="flex-wrap"
                :color="state.reqResponse.type"
                :show="!!state.reqResponse.message"
              >
                {{ state.reqResponse.message }}
                <div v-if="state.reqResponse.errors" class="w-100">
                  <div
                    v-for="(err, index) in state.reqResponse.errors"
                    :key="index"
                  >
                    {{ err[0] }}
                  </div>
                </div>
              </CAlert>
              <div class="cs-file-input">
                <div class="upload-btn">
                  <i class="fi flaticon-upload me-1"></i> BROWSE
                </div>
                <input
                  id="csvUsers"
                  type="file"
                  class="form-control"
                  @change="getFile"
                />
              </div>

              <div class="text-center mt-4 mb-2">
                <button
                  v-if="state.params.file"
                  class="btn bg-gradient-blue text-white px-5"
                  @click="importUsers"
                >
                  Submit
                </button>
              </div>
            </div>
          </CCol>
        </CRow>
      </CContainer>
      <template #footer-wrapper>
        <div></div>
      </template>
    </CModal>
    <!-- Faild users modal -->
    <CModal
      backdrop="static"
      :visible="state.showSuccessModal"
      :close-on-backdrop="false"
    >
      <div class="pt-4 px-4 create-popup-header">
        <div
          class="d-inline-block bg-gradient-blue text-white rounded-circle p-1 lh-normal cursor-pointer"
          @click="state.showSuccessModal = false"
        >
          <span class="close-icon"></span>
        </div>
      </div>
      <CContainer class="px-5 py-4">
        <CRow>
          <CCol md="12">
            <h3>
              Your file has been submitted click
              <router-link to="/file-uploads"> HERE </router-link> to be
              redirected to the file processing screen
            </h3>
          </CCol>
        </CRow>
      </CContainer>
      <template #footer-wrapper>
        <div></div>
      </template>
    </CModal>
  </div>
</template>

<script>
import { reactive } from "vue"
import { useStore } from "vuex"
import HelpCenterButton from "@/v3components/HelpCenterButton"
import RequestErrorsHandler from "@/mixins/RequestErrorsHandler"
import Loader from "@/v3components/shared/Loader/Loader"

export default {
  name: "ImportUsers",
  components: {
    HelpCenterButton,
    Loader
  },
  mixins: [RequestErrorsHandler],
  setup() {
    const store = useStore()

    const state = reactive({
      isLoading: false,
      isModalVisible: false,
      params: {
        auth_type: null,
        fileName: "",
        file: null
      },
      showSuccessModal: false,
      reqResponse: {
        type: "",
        message: "",
        errors: {}
      }
    })

    const getFile = (event) => {
      const files = event.target.files
      if (files && files.length) {
        state.params.file = files[0]
        event.target.value = ""
      }
    }
    const showModal = (event) => {
      if (event.target.classList && event.target.classList.value !== "") {
        state.isModalVisible = true
      }
    }

    const importUsers = () => {
      if (state.params.file) {
        state.isLoading = true
        const data = new FormData()
        data.append("csv_file", state.params.file)
        resetResponseMessages()
        store
          .dispatch("usersImport/importUsers", data)
          .then((response) => {
            const data = response.data.data
            if (data && data.file_name) {
              state.params.fileName = data.file_name
              state.isModalVisible = false
              setTimeout(() => {
                state.showSuccessModal = true
              }, 1000)
            }
            state.isLoading = false
          })
          .catch((err) => {
            state.isLoading = false
            const response = err?.response?.data
            setErrorResponse(response?.message, response?.errors)
          })
      }
    }

    const openFileIpnut = (inputId) => {
      document.getElementById(inputId).click()
    }

    const resetImportData = () => {
      resetResponseMessages()
      state.params = {
        auth_type: null,
        fileName: "",
        file: null
      }
    }

    const resetResponseMessages = () => {
      state.reqResponse = {
        type: "",
        message: "",
        errors: {}
      }
    }

    const setErrorResponse = (message, errors) => {
      state.reqResponse = {
        type: "danger",
        message: message ? message : "Something went wrong!",
        errors: errors ? errors : {}
      }
    }

    return {
      state,
      getFile,
      showModal,
      importUsers,
      openFileIpnut,
      resetImportData
    }
  }
}
</script>
