<template>
  <div v-if="pass.user" class="user-history-pass-box d-flex align-items-center">
    <div class="user-history-pass-flag">
      <PassFlag :pass="pass" />
      <div v-if="isPassEditable" class="cursor-pointer" @click="passTimeEdit()">
        <i class="ri-pencil-line"></i>
      </div>
    </div>
    <div class="flex-1">
      <PassStatus class="mb-2" :pass="pass" />
      <div class="user-info d-flex align-items-center">
        <div v-show="showStudentPhotos" class="c-avatar-pass-history me-2">
          <img
            width="40"
            height="40"
            :src="
              pass.user.avatar
                ? pass.user.avatar
                : 'img/avatars/user-avatar.jpeg'
            "
            class="c-avatar-pass-history-img"
          />
        </div>
        <div>{{ pass.user.first_name }} {{ pass.user.last_name }}</div>
        <div v-if="pass.edited_at" class="is-edited d-flex m-0">EDITED</div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, inject } from "vue"
import { useStore } from "vuex"
import EditPassHistoryTimeForm from "@/v3components/Forms/EditPassHistoryTimeForm"
import PassStatus from "@/v3components/shared/PassComponents/PassStatus.vue"
import PassFlag from "./PassFlag.vue"
export default {
  props: {
    pass: {
      type: Object,
      required: true
    }
  },
  components: {
    PassStatus,
    PassFlag
  },
  setup(props) {
    const store = useStore()
    const modal = inject("modal")

    const currentUser = computed(() => store.getters["authentication/user"])
    const autoPassLimits = computed(() => store.getters["rooms/autoPassLimits"])
    const showStudentPhotos = computed(
      () => store.getters["schools/showStudentPhotos"]
    )

    const isPassEditable = computed(() => {
      if (currentUser.value) {
        if (props.pass.approved_by === currentUser.value.id) {
          return true
        }
        if (props.pass.completed_by === currentUser.value.id) {
          return true
        }
        if (props.pass.approved_with == "autopass" && autoPassLimits.value) {
          const autoPassesRooms =
            autoPassLimits.value.auto_pass_preference_to_user.map(
              (el) => el.auto_pass.room_id
            )
          if (autoPassesRooms.includes(props.pass.from.id)) {
            return true
          }
          if (autoPassesRooms.includes(props.pass.to.id)) {
            return true
          }
          return false
        }
      }
      return false
    })

    const onPassTimeChange = (pass) => {
      store.commit("passes/UPDATE_PASS_HISTORY", pass)
    }

    const passTimeEdit = () => {
      modal.open(EditPassHistoryTimeForm, {
        size: "lg",
        title: null,
        props: {
          pass: props.pass,
          events: { passTimeChange: onPassTimeChange }
        }
      })
    }

    return { isPassEditable, passTimeEdit, showStudentPhotos }
  }
}
</script>
