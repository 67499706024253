<template>
  <BaseButton
    v-bind="{ prependIcon, appendIcon }"
    :class="{
      active,
      rounded,
      'rounded-start': roundedStart,
      'rounded-end': roundedEnd
    }"
    class="v3-pill-button"
  >
    <slot></slot>
  </BaseButton>
</template>

<script>
import BaseButton from "./BaseButton.vue"

export default {
  components: { BaseButton },
  props: {
    prependIcon: String,
    appendIcon: String,
    active: Boolean,
    rounded: Boolean,
    roundedStart: Boolean,
    roundedEnd: Boolean
  }
}
</script>

<style></style>
