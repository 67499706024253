<template>
  <div
    v-if="hasErrorMessage"
    class="visitor-error-message"
    :class="{
      bold: bold,
      center: center,
      small: small,
      large: large,
      whitespace: whitespace,
      [textClasses]: true
    }"
  >
    {{ errorMessage }}
  </div>
</template>

<script>
import { computed, onMounted, onUnmounted, ref } from "vue"
export default {
  name: "VisitorErrorHandler",
  props: {
    error: {
      type: Object,
      default: null
    },
    keepAlive: {
      type: Boolean,
      default: false
    },
    textClasses: {
      type: String,
      default: ""
    },
    bold: {
      type: Boolean,
      default: false
    },
    center: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    large: {
      type: Boolean,
      default: false
    },
    whitespace: {
      type: Boolean,
      default: false
    }
  },
  emits: ["done"],
  setup(props, { emit }) {
    const timeoutId = ref(null)
    const errorMessage = computed(() => {
      return (
        props?.error?.response?.data?.message || props?.error?.message || ""
      )
    })
    const hasErrorMessage = computed(() => {
      return errorMessage?.value?.trim()?.length > 0 || false
    })

    onMounted(() => {
      const time = props.keepAlive ? 30000 : 10000
      timeoutId.value = setTimeout(() => {
        emit("done")
      }, time)
    })

    onUnmounted(() => {
      if (timeoutId.value) clearTimeout(timeoutId.value)
    })

    return {
      hasErrorMessage,
      errorMessage
    }
  }
}
</script>

<style scoped></style>
