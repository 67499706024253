<template>
  <div>
    <div class="detention-info d-flex pe-2 pb-3">
      <div class="detention-info-time">{{ formatedTime }}</div>
      <div
        class="detention-info-detention-color me-2"
        :style="`background-color:${
          detention.color ? detention.color : 'white'
        }`"
      ></div>

      <div>
        <div>{{ detention.name }}</div>
        <div class="detention-info-room">{{ detention.room.name }}</div>
      </div>
      <div class="detention-info-count ms-auto d-flex">
        <span
          @click="onClickDetention(detention)"
          :style="
            detention.tardy_appointment_detentions_count > 0
              ? 'cursor:pointer'
              : 'cursor:default'
          "
          >{{ tardyAppointmentDetentionsCount }}</span
        >
        <i class="ri-group-line"></i>
        <IconButton
          :icon="'ri-edit-2-line'"
          @click="onClickEdit(detention)"
          v-if="isEditableDetention"
        >
        </IconButton>
      </div>
    </div>
    <div
      v-if="
        state.studentsListVisible &&
        detention.tardy_appointment_detentions_count > 0
      "
      class="mb-3"
    >
      <div class="detention-info-students">
        <div
          v-for="(student, index) in state.studentsList.data"
          :key="index"
          class="detention-info-student"
        >
          <div class="detention-info-student-container">
            <div class="detention-info-student-text">
              {{ index + 1 }}
            </div>
            <div class="detention-info-student-text">
              {{ student.full_name }}
            </div>
          </div>
          <div class="detention-info-student-text status">
            {{ student.status }}
          </div>
        </div>
      </div>
      <div class="detention-info-download mb-2">
        <span>Download list</span>
        <IconButton
          :icon="'ri-download-2-line'"
          @click="onDownloadList(detention)"
        >
        </IconButton>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex"
import { reactive, computed } from "vue"
import IconButton from "../Buttons/IconButton.vue"
import moment from "moment-timezone"
import helpers from "@/helpers/index"
import download from "@/helpers/downloadCSV"

export default {
  name: "DetentionInfo",
  components: { IconButton },
  props: {
    detention: {
      type: Object,
      default: null
    },
    detentionDate: {
      type: String,
      default: ""
    },
    selectedDate: {
      type: String,
      default: ""
    },
    isEditableDetention: {
      type: Boolean,
      default: true
    }
  },
  emits: ["cancel", "onClickDetention"],
  setup(props, { emit }) {
    const store = useStore()
    const state = reactive({
      detentions: [],
      studentsList: [],
      studentsListVisible: false,
      isProcessing: false,
      serverErrors: {},
      serverRes: null,

      form: {
        selectedDetention: null,
        detentionDate: null
      }
    })

    const formatedTime = computed(() => {
      return props.detention.default_start_time
        ? moment(props.detention.default_start_time, "hh:mm:ss").format(
            "h:mm a"
          )
        : 0
    })

    const tardyAppointmentDetentionsCount = computed(() => {
      return props.detention.tardy_appointment_detentions_count
        ? props.detention.tardy_appointment_detentions_count
        : 0
    })

    const onClickDetention = (detention) => {
      getDetentionCalendarUsers(detention.id)
    }

    const getDetentionCalendarUsers = (detentionId) => {
      state.studentsListVisible = state.studentsListVisible ? false : true
      if (
        props.detention.tardy_appointment_detentions_count > 0 &&
        state.studentsListVisible
      ) {
        const detentionDate = moment(props.selectedDate).format("YYYY-MM-DD")
        const params = {
          date: detentionDate,
          id: detentionId
        }
        store
          .dispatch("detentions/getDetentionsCalendarUsers", params)
          .then((res) => {
            state.studentsList = res.data
          })
      }
    }

    const onClickEdit = (detentionId) => {
      emit("onClickDetention", detentionId)
    }

    const onDownloadList = (params) => {
      const formatDate = helpers.currTzDate(props.detentionDate, "YYYY-MM-DD")
      store
        .dispatch("detentions/getCSVdetentionExport", {
          id: params.id,
          date: formatDate
        })
        .then((response) => {
          if (response.data) {
            download.CSVExport(
              response.data,
              params.name + "_" + props.detentionDate.format("D MMM dddd")
            )
          }
        })
    }

    return {
      state,
      formatedTime,
      tardyAppointmentDetentionsCount,
      onClickDetention,
      onClickEdit,
      onDownloadList
    }
  }
}
</script>
