<template>
  <div v-if="props.actions" class="action-dots-wrapper">
    <IconButton
      :icon="'ri-more-2-fill'"
      @focus="toggleModal"
      @blur="toggleModal"
      class="action-dots"
      :data-test-id="testId"
    />
    <div v-show="state.openModal" class="action-modal">
      <div
        data-test-id="action"
        v-for="action in actions"
        :key="action.id"
        class="action"
        @click="onClickAction(action)"
      >
        <div :class="'action-icon ' + action.class">
          <i :class="action.icon"></i>
        </div>
        <div class="action-title">
          <span>{{ action.label }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive } from "vue"
import IconButton from "../Buttons/IconButton"

export default {
  name: "Actions",
  components: { IconButton },
  props: {
    actions: {
      type: Array
    },
    testId: {
      type: String,
      default: ""
    },
  },
  emits: ["clickAction"],
  setup(props, { emit }) {
    const state = reactive({
      openModal: false
    })

    const toggleModal = () => {
      setTimeout(() => {
        state.openModal = !state.openModal
      }, 200)
    }

    const onClickAction = (action) => {
      emit("clickAction", action)
    }

    return {
      state,
      props,
      toggleModal,
      onClickAction
    }
  }
}
</script>
