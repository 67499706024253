<template>
  <div>
    <CModal
      backdrop="static"
      v-if="isForCancel"
      :visible="state.modal.isVisible.cancel"
      :close-on-backdrop="false"
    >
      <div class="pt-4 px-4 create-popup-header">
        <div
          class="d-inline-block bg-gradient-blue text-white rounded-circle p-1 lh-normal cursor-pointer"
          @click="closeCancelModal()"
        >
          <span class="close-icon"></span>
        </div>
      </div>
      <div v-if="pass" class="px-3">
        <h3 class="text-center mb-4">Cancel Pass</h3>
        <AptPassCancelation
          :pass="pass"
          :count-type="countType"
          :is-beyond="isPassBeyond"
        />
      </div>
      <template #footer-wrapper>
        <div></div>
      </template>
    </CModal>

    <Loader :is-processing="state.isLoading" classes="position-absolute" />
    <div class="table-actions">
      <div v-if="isForAcknowledge">
        <button
          class="btn btn-comfirm w-100 mb-2"
          @click="acknowledgeAppointmentPass(pass.id)"
        >
          Acknowledge
        </button>
      </div>
      <div v-if="isForConfirm" data-test-id="apt-pass-confirm-btn">
        <button
          class="btn btn-comfirm w-100 mb-2"
          @click="confirmAppointmentPass(pass.id)"
        >
          Confirm
        </button>
      </div>

      <div class="d-flex">
        <button
          v-if="isForEdit"
          class="btn btn-edit me-2"
          @click="editAppointmentPass(pass)"
        >
          <i class="flaticon-edit me-1"></i> edit
        </button>
        <button
          v-if="isForCancel"
          class="btn btn-cancel"
          @click="showCancelModal()"
        >
          cancel
        </button>
        <button
          v-if="isForDelete"
          class="btn btn-cancel ms-1"
          @click="state.modal.isVisible.delete = true"
        >
          Delete
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, computed } from "vue"
import { useStore } from "vuex"
import Loader from "@/v3components/shared/Loader/Loader"
import helpers from "@/helpers/index"
import AptPassCancelation from "@/v3components/Forms/AptPassCancelation"

export default {
  name: "AptPassActionButtons",
  components: {
    Loader,
    AptPassCancelation
  },
  props: {
    pass: {
      type: Object,
      required: true
    },
    countType: {
      type: String,
      default: ""
    },
    isPassBeyond: {
      type: Boolean,
      default: false
    }
  },
  emits: ["editAppPass"],
  setup(props, { emit }) {
    const store = useStore()

    const state = reactive({
      isLoading: false,
      modal: {
        isVisible: {
          delete: false,
          cancel: false
        }
      }
    })

    const isForAcknowledge = computed(() => {
      return props.pass.is_for_acknowledge
    })

    const isForConfirm = computed(() => {
      return props.pass.is_for_confirm
    })

    const isForCancel = computed(() => {
      return props.pass.can_be_canceled && !props.pass.cancel_reason
    })

    const isForDelete = computed(() => {
      return props.pass.can_be_deleted
    })

    const isForEdit = computed(() => {
      return props.pass.can_be_edited
    })

    const confirmAppointmentPass = (passID) => {
      state.isLoading = true
      store
        .dispatch("adultAptPass/confirmAppointmentPass", passID)
        .then(() => {
          state.isLoading = false
          incrementTodayCounter()
          decrementCurrentCounter()
        })
        .catch(() => {
          state.isLoading = false
        })
    }

    const acknowledgeAppointmentPass = (passID) => {
      state.isLoading = true
      store
        .dispatch("adultAptPass/acknowledgeAppointmentPass", passID)
        .then(() => {
          state.isLoading = false
        })
        .catch(() => {
          state.isLoading = false
        })
    }

    const editAppointmentPass = (pass) => {
      emit("editAppPass", pass)
    }

    const decrementCurrentCounter = () => {
      if (
        props.countType === "appointments_future_awaiting" ||
        props.countType === "appointments_awaiting"
      ) {
        store.commit("dashboardTable/DECREMENT_STAT", props.countType)
      }
    }

    const incrementTodayCounter = () => {
      if (
        props.countType === "awaitingTodayAppointmentsCount" ||
        props.countType === "appointments_awaiting"
      ) {
        store.commit("dashboardTable/INCREMENT_STAT", "todayAppointmentsCount")
      }
    }

    const closeCancelModal = () => {
      state.modal.isVisible.cancel = false
      helpers.setModalToggleClass(false)
    }

    const showCancelModal = () => {
      state.modal.isVisible.cancel = true
      helpers.setModalToggleClass(true)
    }

    return {
      state,
      isForAcknowledge,
      isForConfirm,
      isForCancel,
      isForDelete,
      isForEdit,
      confirmAppointmentPass,
      acknowledgeAppointmentPass,
      editAppointmentPass,
      decrementCurrentCounter,
      incrementTodayCounter,
      closeCancelModal,
      showCancelModal
    }
  }
}
</script>
