<template>
  <div>
    <div
      @click="showModal"
      class="position-relative rounded-panel px-4 py-3 mt-4"
      style="background-color: #b7b7b7"
    >
      <div
        class="d-flex justify-content-between align-items-center py-2 cursor-pointer"
      >
        <b
          class="text-value-lg font-weight-light d-block rounded-panel-title text-white"
        >
          Import Student Photos
          <HelpCenterButton
            width="16"
            height="16"
            classes="mr-2"
            content-key="import_photos_user_menu"
            :is-old="true"
          />
        </b>
        <span
          class="d-flex align-items-center justify-content-center text-white rounded-circle p-2"
        >
          <i class="flaticon-upload text-value-lg"></i>
        </span>
      </div>
    </div>

    <!-- Import Modal -->
    <CModal
      :visible="state.isModalVisible"
      :close-on-backdrop="false"
      backdrop="static"
      class="ps__child--consume"
      size="xl"
    >
      <div
        class="pt-4 px-4 create-popup-header d-flex align-items-center justify-content-between"
      >
        <div
          @click=";(state.isModalVisible = false), resetImportData()"
          class="d-inline-block bg-gradient-blue text-white rounded-circle p-1 lh-normal cursor-pointer"
        >
          <span class="close-icon"></span>
        </div>
      </div>
      <Loader :is-processing="state.isLoading" />
      <CContainer class="px-5 py-3">
        <CRow>
          <CCol md="12">
            <h2>Steps for adding student photos to your Pass system</h2>
            <p>
              1. Make sure your photos are formatted as JPEGs or PNGs.<br />
              <ul class="my-2">
                <li>You can upload individual photos or a ZIP file.</li>
              </ul>
              2. Name each photo with the same student number that appears in Pass.<br />
              3. Click “Browse” and locate the prepared photos or ZIP file.<br />
              4. Click “Submit“<br />
              5. It will take up to 48 hours to see photos in your system.<br />
            </p>
            <h2>Guidelines</h2>
            <ul>
              <li>If you are uploading individual photos, you can upload up to 50 at one time.</li>
              <li>If you are uploading a ZIP file, it must be less than 25 MB.</li>
              <li>If after 48 hours you do not see student photos in your system, please create a 
                <a :href="state.helpDeskUrl" target="_blank"><u>Help Desk ticket.</u></a>
              </li>
            </ul>
            <p>For details and further information, click on the blue “?” or see the
              “<a :href="state.adminGuidePictureImportUrl" target="_blank">Importing Student Pictures</a>”
              section in your <a :href="state.adminGuideUrl" target="_blank">Admin Guide</a>.
            </p>
            <div>
              <div class="form-group">
                <input
                  @click="openFileIpnut('photos')"
                  :class="{
                    'is-invalid': Object.keys(state.reqResponse.errors).length > 0
                  }"
                  readonly
                  type="text"
                  class="form-control w-auto pr-5"
                  :value="state.params.fileName ? state.params.fileName : ''"
                />
                <div
                  class="invalid-feedback"
                  v-if="state.reqResponse.errors['photos']"
                >
                  {{ state.reqResponse.errors["photos"][0] }}
                </div>
              </div>
              <CAlert
                v-if="state.reqResponse.message"
                class="flex-wrap"
                :color="state.reqResponse.type"
                :show="!!state.reqResponse.message"
              >
                {{ state.reqResponse.message }}
                <div class="w-100" v-if="state.reqResponse.errors">
                  <div v-for="(err, index) in state.reqResponse.errors" :key="index">
                    {{ err[0] }}
                  </div>
                </div>
              </CAlert>
              <div class="cs-file-input">
                <div class="upload-btn">
                  <i class="fi flaticon-upload mr-1"></i> BROWSE
                </div>
                <input
                  @change="getFile"
                  id="photos"
                  type="file"
                  multiple="multiple"
                  accept="image/png,image/jpeg,.zip,.rar,.7zip"
                  class="form-control"
                />
              </div>

              <div class="text-center mt-4 mb-2">
                <button
                  v-if="state.params.files && state.params.files.length > 0"
                  @click="importPhotos"
                  class="btn bg-gradient-blue text-white px-5"
                >
                  Submit
                </button>
              </div>
            </div>
          </CCol>
        </CRow>
      </CContainer>
      <template #footer-wrapper>
        <div></div>
      </template>
    </CModal>
    <!-- Success upload modal -->
    <CModal :visible="state.showSuccessModal" :close-in-backdrop="false">
      <div class="pt-4 px-4 create-popup-header">
        <div
          @click="state.showSuccessModal = false"
          class="d-inline-block bg-gradient-blue text-white rounded-circle p-1 lh-normal cursor-pointer"
        >
          <span class="close-icon"></span>
        </div>
      </div>
      <CContainer class="px-5 pt-2 pb-4">
        <CRow>
          <CCol md="12">
            <h3>
              Your student photos were received. Please allow 48 hours for your
              photos to appear in your Pass system.
            </h3>
          </CCol>
        </CRow>
      </CContainer>
    </CModal>
  </div>
</template>

<script>
import { reactive } from "vue"
import { useStore } from "vuex"
import RequestErrorsHandler from "@/mixins/RequestErrorsHandler"
import HelpCenterButton from "@/v3components/HelpCenterButton"
import Loader from "@/v3components/shared/Loader/Loader"

export default {
  name: "ImportStudentPhotos",
  mixins: [RequestErrorsHandler],
  components: { Loader, HelpCenterButton },
  setup() {
    const store = useStore()

    const state = reactive({
      isLoading: false,
      isModalVisible: false,
      params: {
        auth_type: null,
        fileName: "",
        files: []
      },
      showSuccessModal: false,
      helpDeskUrl: "https://support.securly.com/",
      adminGuideUrl: "https://docs.google.com/document/d/e/2PACX-1vR-EC_d3lpr_eMXLOTM9ebzqIdPk2s47RM7dwTBlyU-Kqy0oTCip3sYU9dYvS9fOw/pub",
      adminGuidePictureImportUrl: "https://docs.google.com/document/d/e/2PACX-1vR-EC_d3lpr_eMXLOTM9ebzqIdPk2s47RM7dwTBlyU-Kqy0oTCip3sYU9dYvS9fOw/pub#h.vx1227",
      reqResponse: {
        type: "",
        message: "",
        errors: {}
      }
    })

    const getFile = (event) => {
      resetImportData()
      const files = event.target.files
      if (files && files.length && files.length <= 50) {
        for (let i = 0; i < files.length; i++) {
          state.params.files.push(files[i])
        }
        if (files.length > 1) {
          state.params.fileName = state.params.fileName.concat(
            files[0].name,
            ", ",
            files[1].name,
            "..."
          )
        } else {
          state.params.fileName = files[0] ? files[0].name : ""
        }
        event.target.value = ""
      } else {
        setErrorResponse(
          "When uploading individual photos, the limit is 50 photos at a time."
        )
      }
    }

    const showModal = (event) => {
      if (event.target.classList && event.target.classList.value !== "") {
        state.isModalVisible = true
      }
    }

    const importPhotos = () => {
      if (state.params.files && state.params.files.length > 0) {
        state.isLoading = true
        const data = new FormData()
        for (let i = 0; i < state.params.files.length; i++) {
          const file = state.params.files[i]
          data.append("photos[" + i + "]", file)
        }
        resetResponseMessages()
        store
          .dispatch("usersImport/importPhotos", data)
          .then(() => {
            state.isModalVisible = false
            state.showSuccessModal = true
            state.isLoading = false
            resetImportData()
          })
          .catch((err) => {
            if (
              err &&
              err.response &&
              err.response.status &&
              err.response.status == 413
            ) {
              state.isLoading = false
              setErrorResponse("The file must not be bigger than 25 MB.")
            } else {
              state.isLoading = false
              const response = err?.response?.data
              setErrorResponse(response?.message, response?.errors)
            }
          })
      }
    }

    const openFileIpnut=(inputId) =>{
      document.getElementById(inputId).click()
    }

    const resetImportData=() =>{
      resetResponseMessages()
      state.params = {
        auth_type: null,
        fileName: "",
        files: []
      }
    }

    const setErrorResponse = (message, errors) => {
      state.reqResponse = {
        type: "danger",
        message: message ? message : "Something went wrong!",
        errors: errors ? errors : {}
      }
    }

    const resetResponseMessages=() =>{
      state.reqResponse = {
        type: "",
        message: "",
        errors: {}
      }
    }

    return {
      state,
      getFile,
      showModal,
      importPhotos,
      openFileIpnut,
      resetImportData,
      resetResponseMessages
    }
  }
}
</script>
